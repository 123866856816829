import React, { Component } from 'react';
import { Link } from 'gatsby';

class ProductComponent extends Component {
    render() {
        const { data } = this.props;
        return (
            <div className="col-md-6 col-12 my-5 post-component py-4">
                <Link to={data.fields.slug}>
                    <div className="row justify-content-center">
                        <div className="col-12 text-center mb-5">
                            <img src={data.frontmatter.featuredImage} alt={data.frontmatter.title} className="w256 mx-auto" />
                        </div>
                        <div className="col-md-10 col-">
                            <h4 className="text-center my-3 text-dark t">{data.frontmatter.title}</h4>
                            <p className="text-center my-3 text-dark">{data.frontmatter.subtitle}</p>
                        </div>
                    </div>
                </Link>
            </div>
        )
    }
}

export default ProductComponent;
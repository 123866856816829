import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import OtherProductComponent from './OtherProductComponent';

class OtherProductsContainer extends Component {
    render() {
        return (
            <div className="row justify-content-center my-5">
                <div className="col-12">
                    <h4 className="text-center c-a">دیگر پروژه‌های کمک به مقابله با فیلترینگ و سانسور اینترنتی</h4>
                </div>
                <OtherProductComponent
                    link="https://www.torproject.org/"
                    title='تور'
                    imageSrc={withPrefix('/img/torproject.png')}
                />
                <OtherProductComponent
                    link="https://psiphon.ca/"
                    title='سایفون'
                    imageSrc={withPrefix('/img/psiphonproject.png')}
                />
                <OtherProductComponent
                    link="https://getlantern.org"
                    title='لنترن'
                    imageSrc={withPrefix('/img/lanternproject.png')}
                />
                <OtherProductComponent
                    link="https://guardianproject.info/"
                    title='پروژه گاردین'
                    imageSrc={withPrefix('/img/guardianproject.png')}
                />
            </div>
        )
    }
}


export default OtherProductsContainer;
import React, { Component } from 'react';

class OtherProductComponent extends Component {
    render() {
        const data = this.props;
        return (
            <div className="col-md-2 col-6 text-center">
                <a href={data.link}>
                    <div className="row">
                        <div className="col-12 my-2">
                            <img src={data.imageSrc} alt={data.title} className="img w-75" />
                        </div>
                    </div>
                </a>
            </div>
        )
    }
}

export default OtherProductComponent;
import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductComponent from '../components/ProductComponent';
import OtherProductsContainer from '../components/OtherProductsContainer';
import { Helmet } from "react-helmet";

class ProductList extends Component {
    render() {
        const productList = this.props.data.allMarkdownRemark;
        const productHeader = this.props.data.site.siteMetadata.product;
        const { siteMetadata } = this.props.data.site;
        return (
            <Layout  newsletter="true" about="true">
                <Helmet>
                    <title>دانلود فیلترشکن | فیلترشکن‌ها</title>
                    <link rel="canonical" href={`https://filtershekanha.com/download/`} />
                    <meta name="description" content={`${productList.description || siteMetadata.main.description }`} />
                    <meta name="keywords" content={`${productList.keyword || siteMetadata.main.keyword }`} />
                    <meta name="twitter:title" content={`${productList.title}`} />
                    <meta name="twitter:description" content={`${productList.description}`} />
                    <meta name="twitter:image" content={`${siteMetadata.main.cover }`} />
                    <meta name="twitter:url" content={`https://filtershekanha.com/download/`} />
                    <meta property="og:url" content={`https://filtershekanha.com/download/`} />
                    <meta property="og:image" content={`${siteMetadata.main.cover }`} />
                    <meta property="og:title" content={`${productList.title}`} />
                    <meta property="og:description" content={`${productList.description || siteMetadata.main.description }`} />
                </Helmet> 
                <div className="row my-md-5 py-md-5"></div>
                <div className="row justify-content-center my-5 dir-rtl">
                    <div className="col-md-8 col-12 my-3 text-center">
                        <h1 className="text-center"><span className="bg-title px-4">{productHeader.title}</span></h1>
                    </div>
                    <div className="col-md-8 col-12 my-4">
                        <p className="text-center">{productHeader.description}</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    { productList.edges.map(({ node }, i) => (
                        <ProductComponent key={i} data={node} />                            
                    ))}
                </div>
                <div className="my-md-3 py-md-3">
                    <hr width="300" className="bg-primary my-5" />
                </div>
                <OtherProductsContainer />
                <div className="row my-md-4 py-md-4" />
            </Layout>
        )
    }
}

export default ProductList;

export const listQuery = graphql`
    query productListQuery
    {  
        site {
            siteMetadata {
                main {
                    title
                    description
                    image
                    cover
                    keyword
                }                
                product {
                    title
                    description
                    keyword
                }
            }
        },
        allMarkdownRemark(      
            sort: { fields: [frontmatter___date], order: DESC } 
            filter: { frontmatter: {posttype: {eq: "product"} } } 
        ) {
        edges {
            node {
                excerpt
                fields{
                    slug
                }
                frontmatter {
                    date
                    title
                    subtitle
                    description
                    slug
                    image
                    featuredImage                   
                }
            }
        }
        }
    }
`
